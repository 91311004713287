import _hasProperty from "./hasProperty.js";
var exports = {};
const hasProperty = _hasProperty;

class ErrorWithCause extends Error {
  constructor(message, options) {
    if (options === void 0) {
      options = {};
    }

    super(message);
    this.cause = options.cause;

    if (this.cause && hasProperty(this.cause, "isNetworkError")) {
      this.isNetworkError = this.cause.isNetworkError;
    }
  }

}

exports = ErrorWithCause;
export default exports;